import caConsultDispatcher from '../action/caConsult';

const initialState = {
  list: [],
  paging: {
    pageIndex: 0,
    pageSize: 10,
    totalItem: 0,
    totalPage: 0
  }
};

const caConsultReducer = caConsultDispatcher(initialState, {
  [caConsultDispatcher.getDataSuccess]: (state, payload) => ({
    list: payload.data.list,
    paging: {
      ...payload.data,
      list: undefined
    }
  })
});

export default caConsultReducer;
