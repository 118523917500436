/* eslint-disable react-hooks/exhaustive-deps */
import {
  VideoIcon,
  MicIcon,
  SpeakerIcon,
  SpeakerOffIcon,
  MicOffIcon,
  VideoOffIcon,
  ShareScreenIcon,
  MessageIcon,
  NRICIcon
} from '../../assets/svg';
import { isFirefox } from '../../helpers';
import { useContainerDimensions } from '../../helpers/useHookHelpers';
import { endCall } from '../../helpers/waitingRoomSignalR';
import ModalUI from '../../module/common/componentUI/ModalUI';
import waitingRoomDispatcher from '../../module/waiting-room/action/waitingRoom';
import ChatDrawer from '../ChatPanel';
import ModalPresenter from '../ModalPresenter';
import NRICPanel from '../NRICPanel';
import './styles.scss';
import NewAgoraInstance from '@/agora/agoraInstance4x';
import { LoadingOutlined } from '@ant-design/icons';
import { Drawer, Fab, Modal, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CallRounded, CallEndRounded } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components';

const useStyles = makeStyles({
  paper: {
    width: props => `calc(100% - ${props.modalWidth}px)`,
    height: '100%',
    top: 0,
    left: 0,
    transition: 'width 0.2s'
  },

  chatContainer: {
    width: 360,
    overflow: 'hidden'
  }
});

const StreamPanel = ({
  remoteStreams,
  localStream,
  isMuteVideo,
  isMuteAudio,
  isMuteSpeaker,
  patientId,
  videoChannelId,
  isSharing,
  showChat,
  currentUser,
  channelInfo,
  partner,
  updateListChannels,
  verifyingUserData,
  doVerify,
  showNRIC
}) => {
  const memberAppointment = useSelector(
    state => state.waitingRoom.memberAppointment
  );
  const [modalWidth, setModalWidth] = useState(showChat ? 360 : 0);
  const [hasNewMessage, setHasNewMessage] = useState(false);
  const [chatChannel, setChatChannel] = useState({});
  const [endModal, setEndModal] = useState(false);

  const classes = useStyles({ modalWidth });

  let mainStreamRef = useRef(null);
  const [, , ratio] = useContainerDimensions(mainStreamRef);

  useEffect(() => {
    NewAgoraInstance.instance.joinChannel(videoChannelId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      // clean up memberAppointment
      waitingRoomDispatcher.setState('memberAppointment', null);
    };
  }, []);

  useEffect(() => {
    if (channelInfo) {
      setChatChannel(channelInfo);
    }
    // else createNewChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelInfo]);

  const onClickMessageIcon = () => {
    setModalWidth(!showChat ? 360 : 0);
    waitingRoomDispatcher.setState('showChat', !showChat);
    showNRIC && waitingRoomDispatcher.setState('showNRIC', !showNRIC);
    setHasNewMessage(false);
  };

  const handleToggleNRIC = () => {
    setModalWidth(!showNRIC ? 360 : 0);
    waitingRoomDispatcher.setState('showNRIC', !showNRIC);
    showChat && waitingRoomDispatcher.setState('showChat', !showChat);
  };

  const handleRotateScreen = isCheck => {
    const { DeviceType } = verifyingUserData;
    let transform = '';
    let operator = DeviceType === 'Android' ? '-' : '';
    let degree = DeviceType === 'Android' ? 90 : 0;

    if (isFirefox() && isCheck) {
      transform = `translate(-50%, -50%) rotate(${operator}${degree}deg)`;
    } else transform = '';

    return transform;
  };

  const handleRotateLocalScreen = isCheck => {
    const { DeviceType } = verifyingUserData;
    let transform = '';
    let operator = DeviceType === 'Android' ? '-' : '';
    let degree = DeviceType === 'Android' ? 90 : 0;

    if (isFirefox() && isCheck) {
      transform = `rotate(${operator}${degree}deg)`;
    } else transform = '';

    return transform;
  };

  const StreamGroupBtn = () => {
    return (
      <div className="stream-group-btn">
        <div>
          <Fab
            className={
              isMuteSpeaker
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            aria-label="mute-speaker"
            onClick={() => {
              NewAgoraInstance.instance.muteSpeaker(!isMuteSpeaker);
            }}
          >
            {isMuteSpeaker ? (
              <SpeakerOffIcon style={{ width: 28, height: 28 }} />
            ) : (
              <SpeakerIcon style={{ width: 28, height: 28 }} />
            )}
          </Fab>
          <Fab
            className={
              isMuteAudio
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            aria-label="mute-audio"
            onClick={() => {
              NewAgoraInstance.instance.muteAudio(!isMuteAudio);
            }}
          >
            {isMuteAudio ? (
              <MicOffIcon style={{ width: 28, height: 28 }} />
            ) : (
              <MicIcon style={{ width: 28, height: 28 }} />
            )}
          </Fab>
          <Fab
            className={
              isMuteVideo
                ? 'float-button'
                : 'float-button float-button-selected'
            }
            aria-label="enable-video"
            onClick={() => {
              NewAgoraInstance.instance.muteVideo(!isMuteVideo);
            }}
          >
            {isMuteVideo ? (
              <VideoOffIcon style={{ width: 28, height: 28 }} />
            ) : (
              <VideoIcon style={{ width: 28, height: 28 }} />
            )}
          </Fab>
        </div>
        <Fab
          style={{
            backgroundColor: '#EA6B75',
            color: 'white',
            width: 80,
            height: 80
          }}
          aria-label="add"
          onClick={() => {
            setEndModal(true);
          }}
        >
          <CallRounded style={{ fontSize: 32 }} />
        </Fab>

        <div>
          <Fab
            className={
              !isSharing ? 'float-button' : 'float-button float-button-selected'
            }
            aria-label="sharing"
            onClick={() => {
              if (!isSharing) {
                NewAgoraInstance.instance.joinChannel(videoChannelId, true);
                // shareScreenToPatient(channelId)
              } else {
                NewAgoraInstance.instance.leaveChannel({
                  leaveOnlyShareScreen: true
                });
              }
            }}
          >
            <ShareScreenIcon style={{ width: 28, height: 28 }} />
          </Fab>
          {isEmpty(memberAppointment) ? null : (
            <Fab
              className={
                !showNRIC
                  ? 'float-button'
                  : 'float-button float-button-selected'
              }
              aria-label="NRIC"
              onClick={handleToggleNRIC}
            >
              <NRICIcon style={{ width: 28, height: 28 }} />
            </Fab>
          )}

          <Fab
            className={
              !showChat ? 'float-button' : 'float-button float-button-selected'
            }
            aria-label="chat"
            onClick={() => onClickMessageIcon()}
          >
            {hasNewMessage && <div className="icon-new-message"></div>}
            <MessageIcon style={{ width: 28, height: 28 }} />
          </Fab>
        </div>
      </div>
    );
  };
  return (
    <ModalUI {...{ open: true, classes }}>
      <Modal open={!!endModal} onClose={() => setEndModal(false)}>
        <div>
          <ModalPresenter
            onClose={() => setEndModal(false)}
            Icon={<CallEndRounded style={{ fontSize: 80, color: '#EA6B75' }} />}
            title="End Call"
            onClick={async () => {
              await endCall(patientId);
              NewAgoraInstance.instance.leaveChannel({
                leaveOnlyShareScreen: false
              });
            }}
          >
            <Typography>Click “Continue” to end call</Typography>
          </ModalPresenter>
        </div>
      </Modal>
      <StreamGroupBtn />
      <StyledContainer
        ref={mainStreamRef}
        key="main_stream"
        id="main_stream"
        isSharing={isSharing}
        transform={handleRotateScreen(!isSharing)}
      >
        {isEmpty(remoteStreams) && (
          <LoadingOutlined
            style={{
              fontSize: 80,
              color: '#fff',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}
      </StyledContainer>
      <LocalStream
        style={{
          transform: handleRotateLocalScreen(isSharing)
        }}
      />
      <Drawer
        open={showChat}
        anchor="right"
        variant="persistent"
        classes={{ paper: classes.chatContainer }}
        onClose={onClickMessageIcon}
      >
        <ChatDrawer
          setChatChannel={setChatChannel}
          updateListChannels={updateListChannels}
          partner={partner}
          isOpen={showChat}
          channelInfo={chatChannel}
          onClose={onClickMessageIcon}
          setHasNewMessage={setHasNewMessage}
        />
      </Drawer>
      <Drawer
        open={showNRIC}
        anchor="right"
        variant="persistent"
        classes={{ paper: classes.chatContainer }}
        onClose={handleToggleNRIC}
      >
        <NRICPanel
          onClose={handleToggleNRIC}
          data={verifyingUserData}
          partner={partner}
        />
      </Drawer>
    </ModalUI>
  );
};

const LocalStream = ({ style }) => {
  return (
    <div className="local-stream-container">
      <div
        key="sub_stream"
        id="sub_stream"
        style={{ width: '100%', height: '100%', ...style }}
      />
    </div>
  );
};

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  video {
    height: ${props => (props.isSharing ? `100%` : `200%`)} !important;
    transform: ${props =>
      !isEmpty(props.transform) ? props.transform : 'translate(-50%, -50%)'};

    top: 50% !important;
    left: 50% !important;
  }
`;

// transform: ${props =>
//   !isEmpty(props.transform) ? props.transform : 'translate(-50%, -50%)'};

const mapStateToProps = state => {
  const {
    localStream,
    remoteStreams,
    isMuteAudio,
    isMuteVideo,
    isMuteSpeaker,
    isSharing,
    showChat,
    showNRIC,
    verifyingUserData
  } = state.waitingRoom;
  return {
    localStream,
    remoteStreams,
    isMuteAudio,
    isMuteVideo,
    isMuteSpeaker,
    isSharing,
    showChat,
    showNRIC,
    verifyingUserData
  };
};

export default connect(mapStateToProps)(StreamPanel);
